import React from "react";
import {useTranslation} from "react-i18next";

export const BrandsSection = () => {
    const {t} = useTranslation();
    return (
        <section className="brands-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12">
                        <h2 className="second-heading text-center">{t("brands_section.heading")}</h2>
                    </div>
                </div>
                <div className="row mb-md-5">
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/warba-bank@2x.png" alt="Warba Bank" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/aldar@2x.png" alt="Aldar" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/samsung@2x.png" alt="Samsung" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/visa@2x.png" alt="Visa" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/mastercard@2x.png" alt="Master Card" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/alrajhi-bank@2x.png" alt="Al Rajhi Bank" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-md-5">
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/f-b@2x.png" alt="F&B" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/al-futtaim@2x.png" alt="Al Futtaim" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/hsbc@2x.png" alt="HSBC" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/almajid-al-futtaim@2x.png" alt="AlMajid Al Futtaim" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/vodafone@2x.png" alt="vodafone" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-md-2 col-4 d-flex align-items-center col">
                        <div className="img-holder">
                            <img src="/images/tawuniya@2x.png" alt="tawuniya" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
