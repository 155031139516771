import React, {useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const TeamSection = () => {
    const teamSectionRef = useRef(null);
    useEffect(() => {
        if(window.location.hash === "#loyality_manager") {
            teamSectionRef.current.scrollIntoView({
                behavior:"smooth"
            });
        }
    }, []);
    const {t} = useTranslation();
    return (
        <section className="team-section" ref={teamSectionRef}>
            <div className="container">
                <div className="row">
                    <div className=" col-12">
                        <h2 className="second-heading text-center">{t("contact_page.team_section.heading")}</h2>
                        <ul className="list-unstyled text-center team-list">
                            <li className="text-center">
                                <div className="img-holder">
                                    <img src="/images/julian.png" alt="General Manager KSA" className="img-fluid"/>
                                </div>
                                <div className="social-profile">
                                    <Link to="https://www.linkedin.com/in/julianmorbidelli" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.46687 1.21869C1.817 1.21869 0.738281 2.30208 0.738281 3.72604C0.738281 5.11856 1.78487 6.23285 3.40356 6.23285H3.43488C5.11702 6.23285 6.16388 5.11856 6.16388 3.72604C6.13243 2.30208 5.11702 1.21869 3.46687 1.21869Z"
                                                fill="white"/>
                                            <path d="M1.02344 8.21411H5.84671V22.7251H1.02344V8.21411Z" fill="white"/>
                                            <path
                                                d="M17.6858 7.87354C15.0838 7.87354 13.339 10.3185 13.339 10.3185V8.21411H8.51562V22.7251H13.3388V14.6216C13.3388 14.1878 13.3702 13.7547 13.4977 13.4444C13.8463 12.5782 14.6398 11.6807 15.9723 11.6807C17.7176 11.6807 18.4157 13.0114 18.4157 14.9622V22.7251H23.2385V14.4048C23.2385 9.94762 20.8589 7.87354 17.6858 7.87354Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="mailto:julianm@theentertainerme.com">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.6942 15.0319C12.4675 15.1736 12.2125 15.2302 11.9858 15.2302C11.7591 15.2302 11.5041 15.1736 11.2774 15.0319L0 8.14642V17.2987C0 19.2539 1.58678 20.8406 3.54191 20.8406H20.4581C22.4132 20.8406 24 19.2539 24 17.2987V8.14642L12.6942 15.0319Z"
                                                fill="white"/>
                                            <path
                                                d="M20.458 3.15936H3.54185C1.87007 3.15936 0.453305 4.34945 0.113281 5.93622L12.0141 13.1901L23.8866 5.93622C23.5466 4.34945 22.1298 3.15936 20.458 3.15936Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="tel:+966509256345" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
                                        </svg>
                                    </Link>
                                </div>
                                <p className="designation">{t("contact_page.team_section.general_manager")}</p>
                                <span className="name d-block">{t("contact_page.team_section.general_manager_name")}</span>
                            </li>
                            <li className="text-center">
                                <div className="img-holder">
                                    <img src="/images/rabiya.png" alt="General Manager KSA" className="img-fluid"/>
                                </div>
                                <div className="social-profile">
                                    <Link to="https://www.linkedin.com/in/rabya-tariq-597558172" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.46687 1.21869C1.817 1.21869 0.738281 2.30208 0.738281 3.72604C0.738281 5.11856 1.78487 6.23285 3.40356 6.23285H3.43488C5.11702 6.23285 6.16388 5.11856 6.16388 3.72604C6.13243 2.30208 5.11702 1.21869 3.46687 1.21869Z"
                                                fill="white"/>
                                            <path d="M1.02344 8.21411H5.84671V22.7251H1.02344V8.21411Z" fill="white"/>
                                            <path
                                                d="M17.6858 7.87354C15.0838 7.87354 13.339 10.3185 13.339 10.3185V8.21411H8.51562V22.7251H13.3388V14.6216C13.3388 14.1878 13.3702 13.7547 13.4977 13.4444C13.8463 12.5782 14.6398 11.6807 15.9723 11.6807C17.7176 11.6807 18.4157 13.0114 18.4157 14.9622V22.7251H23.2385V14.4048C23.2385 9.94762 20.8589 7.87354 17.6858 7.87354Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="mailto:rabiya.t@theentertainerme.com">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.6942 15.0319C12.4675 15.1736 12.2125 15.2302 11.9858 15.2302C11.7591 15.2302 11.5041 15.1736 11.2774 15.0319L0 8.14642V17.2987C0 19.2539 1.58678 20.8406 3.54191 20.8406H20.4581C22.4132 20.8406 24 19.2539 24 17.2987V8.14642L12.6942 15.0319Z"
                                                fill="white"/>
                                            <path
                                                d="M20.458 3.15936H3.54185C1.87007 3.15936 0.453305 4.34945 0.113281 5.93622L12.0141 13.1901L23.8866 5.93622C23.5466 4.34945 22.1298 3.15936 20.458 3.15936Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="tel:+966543083546" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
                                        </svg>
                                    </Link>
                                </div>
                                <p className="designation">{t("contact_page.team_section.partnership_manager")}</p>
                                <span className="name d-block">{t("contact_page.team_section.partnership_manager_name1")}</span>
                            </li>
                            <li className="text-center">
                                <div className="img-holder">
                                    <img src="/images/ibrahim.png" alt="Director of B2B" className="img-fluid"/>
                                </div>
                                <div className="social-profile">
                                    <Link to="https://www.linkedin.com/in/ibrahim-mustafa-89214a113" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.46687 1.21869C1.817 1.21869 0.738281 2.30208 0.738281 3.72604C0.738281 5.11856 1.78487 6.23285 3.40356 6.23285H3.43488C5.11702 6.23285 6.16388 5.11856 6.16388 3.72604C6.13243 2.30208 5.11702 1.21869 3.46687 1.21869Z"
                                                fill="white"/>
                                            <path d="M1.02344 8.21411H5.84671V22.7251H1.02344V8.21411Z" fill="white"/>
                                            <path
                                                d="M17.6858 7.87354C15.0838 7.87354 13.339 10.3185 13.339 10.3185V8.21411H8.51562V22.7251H13.3388V14.6216C13.3388 14.1878 13.3702 13.7547 13.4977 13.4444C13.8463 12.5782 14.6398 11.6807 15.9723 11.6807C17.7176 11.6807 18.4157 13.0114 18.4157 14.9622V22.7251H23.2385V14.4048C23.2385 9.94762 20.8589 7.87354 17.6858 7.87354Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="mailto:ibrahim.m@theentertainerme.com">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.6942 15.0319C12.4675 15.1736 12.2125 15.2302 11.9858 15.2302C11.7591 15.2302 11.5041 15.1736 11.2774 15.0319L0 8.14642V17.2987C0 19.2539 1.58678 20.8406 3.54191 20.8406H20.4581C22.4132 20.8406 24 19.2539 24 17.2987V8.14642L12.6942 15.0319Z"
                                                fill="white"/>
                                            <path
                                                d="M20.458 3.15936H3.54185C1.87007 3.15936 0.453305 4.34945 0.113281 5.93622L12.0141 13.1901L23.8866 5.93622C23.5466 4.34945 22.1298 3.15936 20.458 3.15936Z"
                                                fill="white"/>
                                        </svg>
                                    </Link>
                                    <Link to="tel:+966552747546" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
                                        </svg>
                                    </Link>
                                </div>
                                <p className="designation">{t("contact_page.team_section.partnership_manager")}</p>
                                <span className="name d-block">{t("contact_page.team_section.partnership_manager_name2")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}