import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiWrapper} from "../../../api/ApiWrapper";

export const Marketing = (
    {
        marketingCampaign,
        setMarketingCampaign,
        marketingCampaignList,
        advanceReporting,
        setAdvanceReporting,
        advanceReportingOptions,
        setThankyouState,
        businessFinishData,
        marketFinishData,
        initialSetupFee,
        contentLicenseFee,
        marketingFee,
        setMarketingFee,
        setupFee,
        advanceReportingFee,
        setAdvanceReportingFee,
        feeMessage,
        selectedCountries,
        isMonthly,
        summaryData,
    }
    ) => {


    const [isLoadingCalculationSubmission, setIsLoadingCalculationSubmission] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const salesForceForm = useRef(null);

    const {t} = useTranslation();
    useEffect(() => {
        if(marketingCampaign === 'standard') {
            setMarketingFee(parseInt(setupFee.standard_marketing_fee));
        }
    });
    const onCampaignHandler = (event) => {
        setMarketingCampaign(event.target.value);

        if(event.target.value === 'standard') {
            setMarketingFee(parseInt(setupFee.standard_marketing_fee));
        }
        else if(event.target.value === 'quarterly') {
            setMarketingFee(parseInt(setupFee.quarterly_marketing_fee));
        } else if(event.target.value === 'annual') {
            setMarketingFee(parseInt(setupFee.annual_marketing_fee));
        } else {
            setMarketingFee(0);
        }
    }
    const onAdvanceReportingHandler = (event) => {
        setAdvanceReporting(event.target.value);

        if(event.target.value === 'yes') {
            setAdvanceReportingFee(parseInt(setupFee.advance_reporting_fee));
        } else {
            setAdvanceReportingFee(0);
        }
    }

    const onSubmitCalculationHandler = (event) => {
        const currency = t("calculator.currency");
        setFormSubmit(true);
        setIsLoadingCalculationSubmission(true);
        const marketingFinishData = {
            marketingCampaign: marketingCampaign,
            advanceReporting:advanceReporting
        }
        const formData = {...businessFinishData, ...marketFinishData, ...marketingFinishData, ...summaryData};
        event.preventDefault();
        if(salesForceForm.current) {
            salesForceForm.current.click();
        }
        let totalMonths = 1;
        if(isMonthly) {
            const validityToYear = formData.validityTo.getFullYear();
            const validityFromYear = formData.validityFrom.getFullYear();

            const yearDiff = validityToYear - validityFromYear;
            const monthDiff = formData.validityTo.getMonth() - formData.validityFrom.getMonth();
            totalMonths = yearDiff * 12 + monthDiff;
        }

        const totalFee = ((initialSetupFee + contentLicenseFee + marketingFee + advanceReportingFee)/totalMonths).toFixed(3);
        const finalFeeString = `${currency}${localStorage.getItem("selectedLanguage") === 'ar' ? "*" : ""}${totalFee}${localStorage.getItem("selectedLanguage") === 'en' ? "*" : ""}`;
        const optionalData = JSON.stringify({
            '{BUSINESS_EMAIL}': formData.businessEmail,
            '{BUSINESS_CONTACT}': formData.businessContact,
            '{BUSINESS_TYPE}': formData.businessType,
            '{BUSINESS_COMPANY_SIZE}': formData.businessCompanySize,
            '{BUSINESS_PLATFORM}':formData.businessPlatform,
            '{MARKET_REWARDS_UNIT}': formData.rewardsUnit,
            '{MARKET_COUNTRY}': formData.defaultCountry,
            '{MARKET_MERCHANTS}': formData.noOfMerchants,
            '{MARKET_REGIONS}': formData.noOfCountries.length,
            '{MARKET_OFFERS_PER_MERCHANT}':formData.offersPerMerchant,
            '{MARKET_VALIDITY_FROM}':formData.validityFrom,
            '{MARKET_VALIDITY_TO}':formData.validityTo,
            '{MARKETING_CAMPAIGN}':formData.marketingCampaign,
            '{MARKETING_ADVANCE_REPORTING}':formData.advanceReporting,
            '{MONTHLY_CHECK}':
                isMonthly ? "https://app-home-tiles.s3.amazonaws.com/new_featured/rwd/monthly-enable.png"
                    : "https://app-home-tiles.s3.amazonaws.com/new_featured/rwd/monthly-disable.png",
            '{SETUP_FEE}':(initialSetupFee/totalMonths).toFixed(3),
            '{CONTENT_LICENSE_FEE}':(contentLicenseFee/totalMonths).toFixed(3),
            '{OTHER_FEE}':((marketingFee + advanceReportingFee)/totalMonths).toFixed(3),
            '{MARKETING_FEE}':(marketingFee/totalMonths).toFixed(3),
            '{ADVANCE_REPORTING_FEE}':(advanceReportingFee/totalMonths),
            '{TOTAL_FEE}':finalFeeString,
            '{CALCULATION_PERIOD_STRING}':isMonthly ? t("calculator.summary_fields.monthly_fee") : t("calculator.summary_fields.total_fee")
        });
        const emailData = {
            email_template_id: 1215,
            email_to: formData.businessEmail,
            email_template_data:"{}",
            optional_data: optionalData,
            language:document.documentElement.lang,
            priority:1,
        };
        (async () => {
            const data = await ApiWrapper({
                basePath:process.env.REACT_APP_SEND_EMAIL_API_ENDPOINT,
                path:"/send",
                method:"POST",
                data:JSON.stringify(emailData)
            });
            if(data.status_code === 200) {
                setThankyouState(true);
                setFormSubmit(false);
                setIsLoadingCalculationSubmission(false);
            }
        })();
    }

    const onSalesForceSubmit = (e)=>{
        e.preventDefault();
    }

    return (
        <div>
            {isLoadingCalculationSubmission ? <span className="calc_loader submission"></span> : ""}
            <form onSubmit={onSubmitCalculationHandler} className={formSubmit ? "submission_request" : ""}>
                <div className="tab-pane form-wrap">
                <div className="field-wrap">
                    <label className="form-label" htmlFor="Campaign">{t("calculator.marketing_fields.marketing_campaign")}</label>
                    <div className="select-wrap">
                        <select
                            className="form-select"
                            id="Campaign"
                            value={marketingCampaign}
                            onChange={onCampaignHandler}
                        >
                            {marketingCampaignList.map((marketingCampaign) => (
                                <option key={marketingCampaign.id} value={marketingCampaign.value}>{marketingCampaign.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="field-wrap">
                    <label className="form-label" htmlFor="adv_reporting">{t("calculator.marketing_fields.advanced_reporting")}</label>
                    <div className="select-wrap">
                        <select
                            className="form-select"
                            id="adv_reporting"
                            value={advanceReporting}
                            onChange={onAdvanceReportingHandler}
                        >
                            {advanceReportingOptions.map((advanceReporting) => (
                                    <option key={advanceReporting.id} value={advanceReporting.value}>{advanceReporting.label}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
                <button className="btn btn-primary d-block w-100">{t("calculator.done")}</button>
            </form>

        {/* Sales force from */}
            <form
                action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
                method="POST"
                onSubmit = {onSalesForceSubmit}
                className="d-none">
                <input type="hidden" name="oid" value="00Db0000000Hvp3"/>
                <label htmlFor="email">Email</label>
                <input readOnly id="email" maxLength="80" name="email" size="20" type="text" value={businessFinishData.businessEmail}/><br></br>
                Business Type:
                <select  id="00N3V000001mLdY" name="00N3V000001mLdY" title="Business Type">
                    <option defaultValue value={businessFinishData.businessType}>{businessFinishData.businessType}</option>
                </select><br></br>
                Company Size:
                <select  id="00N3V000001mLdd" name="00N3V000001mLdd" title="Company Size">
                    <option defaultValue value={businessFinishData.businessCompanySize}>{businessFinishData.businessCompanySize}</option>
                </select><br></br>
                Who would you want to use the app:
                <select  id="00N3V000001mLdi" name="00N3V000001mLdi" title="Who would you want to use the app">
                    <option defaultValue value={businessFinishData.businessPlatform}>{businessFinishData.businessPlatform}</option>
                </select><br></br>
                How many Rewards app units do you need?:
                <input readOnly id="00N3V000001mLdn" maxLength="250" name="00N3V000001mLdn" size="20" type="text" value={marketFinishData.rewardsUnit}/><br></br>
                Home Country:
                <select  id="00N3V000001mLds" name="00N3V000001mLds" title="Home Country">
                    <option defaultValue value={marketFinishData.defaultCountry}>{marketFinishData.defaultCountry}</option>
                </select><br></br>
                Number of merchants:
                <select  id="00N3V000001mLdx" name="00N3V000001mLdx" title="Number of merchants">
                    <option defaultValue value={marketFinishData.noOfMerchants}>{marketFinishData.noOfMerchants}</option>
                </select><br></br>
                Offers per merchant:
                <select  id="00N3V000001mLe2" name="00N3V000001mLe2" title="Offers per merchant">
                    <option defaultValue value={marketFinishData.offersPerMerchant}>{marketFinishData.offersPerMerchant}</option>
                </select><br></br>
                Valid From:
                <span className="dateInput dateOnlyInput">
                    <input readOnly id="00N3V000001mLe7" name="00N3V000001mLe7" size="12" type="text" value={marketFinishData.validityFrom}/>
                </span><br></br>
                Valid To:
                <span className="dateInput dateOnlyInput">
                    <input readOnly id="00N3V000001mLeC" name="00N3V000001mLeC" size="12" type="text" value={marketFinishData.validityTo}/>
                </span><br></br>
                How often do you run Marketing Campaigns:
                <select  id="00N3V000001mLeH" name="00N3V000001mLeH" title="How often do you run Marketing Campaigns">
                    <option defaultValue value={marketingCampaign}>{marketingCampaign}</option>
                </select><br></br>
                Do you need Advanced Reporting:
                <select  id="00N3V000001mLeM" name="00N3V000001mLeM" title="Do you need Advanced Reporting">
                    <option defaultValue value={advanceReporting}>{advanceReporting}</option>
                </select><br></br>
                Country/Region:
                <select  id="00N3V000001mLgN" name="00N3V000001mLgN" title="Country/Region">
                    <option defaultValue value={selectedCountries}>{selectedCountries}</option>
                </select>
                Contact Number:
                <input readOnly id="00N3V000001mLgc" maxLength="40" name="00N3V000001mLgc" size="20" type="text" value={businessFinishData.businessContact}/><br></br>
                <input type="hidden" name="recordType" id="recordType" value="0123V00000064p7" />
                <button ref = {salesForceForm}></button>
            </form>
        </div>
    )
}