import React, {useEffect} from "react";
import {Hero} from "../components/Home/Hero";
import {PartnersHelp} from "../components/Home/PartnersHelp";
import {ExperienceSection} from "../components/Home/ExperienceSection";
import {PricingSection} from "../components/Home/PricingSection";
import {OtherSolution} from "../components/Home/OtherSolution";
import {FeatureSection} from "../components/Home/FeatureSection";
import {StorySection} from "../components/Home/StorySection";
import {BrandsSection} from "../components/Home/BrandsSection";
import {FAQSection} from "../components/FAQ/FAQSection";
import {useTabContext} from "../context/TabContext";
import {useTranslation} from "react-i18next";

export const Home = () => {
    useEffect(() => {
        // Check if the hash in the URL matches the section ID
        const hash = window.location.hash;
        if (hash) {
            const section = document.querySelector(hash);
            if (section) {
                section.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        }
    }, );
    return (
        <>
            <Hero />
            <main id="main">
                <PartnersHelp />
                <ExperienceSection />
                <PricingSection />
                <OtherSolution />
                <FeatureSection />
                <StorySection />
                <BrandsSection />
                <FAQSection />
            </main>
        </>
    );
}
