import React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {ApiWrapper} from "../../api/ApiWrapper";

export const ContactForm = () => {
    const {t} = useTranslation();

    const [formData, setFormData] = useState({
        username: '',
        email: '',
        contact:'',
        message: '',
    });
    const [formErrors, setFormErrors] = useState({});
    const [isLoadingContactSubmission, setIsLoadingContactSubmission] = useState(false);
    const [thankyouState, setThankyouState] = useState(false);

    const InputChangeHandler = (e) => {
        const { name, value } = e.target;
        if(name === 'contact') {
            if (/^\+?\d*$/.test(value)) {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    // Handle form submission
    const SubmitHandler = (e) => {
        e.preventDefault();
        // Validate the form before submitting
        if (validateForm()) {
            setIsLoadingContactSubmission(true);
            const optionalData = JSON.stringify({
                '{EMAIL}': formData.email,
                '{FIRST_NAME}': formData.username,
                '{PHONE_NUMBER}': formData.contact,
                '{MESSAGE}': formData.message,
            });
            const emailData = {
                email_template_id: 1218,
                email_to: formData.email,
                email_template_data:"{}",
                optional_data: optionalData,
                language:document.documentElement.lang,
                priority:1,
            };

            (async () => {
                const data = await ApiWrapper({
                    basePath:process.env.REACT_APP_SEND_EMAIL_API_ENDPOINT,
                    path:"/send",
                    method:"POST",
                    data:JSON.stringify(emailData)
                });
                if(data.status_code === 200) {
                    setIsLoadingContactSubmission(false);
                    setThankyouState(true);
                    setFormData({
                        username: '',
                        email: '',
                        contact: '',
                        message: '',
                    });
                }
            })();
        }
    };

    const validateForm = () => {
        let errors = {};

        // Validate username
        if (!formData.username.trim()) {
            errors.username = t("contact_page.validation_messages.name");
        }

        // Validate email
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!formData.email.trim()) {
            errors.email = t("contact_page.validation_messages.email");
        }
        if (formData.email && !emailPattern.test(formData.email)) {
            errors.email = t("contact_page.validation_messages.email_invalid");
        }
        if (!formData.contact) {
            errors.contact = t("contact_page.validation_messages.phone");
        }
        if(formData.contact && (formData.contact.length < 10 || formData.contact.length > 15)) {
            errors.contact = t("contact_page.validation_messages.phone");
        }

        // Set formErrors state
        setFormErrors(errors);

        // Return true if there are no errors
        return Object.keys(errors).length === 0;
    };

    const onCloseThankyouState = () => {
        setThankyouState(false);
    }


    return (
        <>
        <section className="pg-contact container">
            <div className="row flex-md-row-reverse">
                <div className="col-md-6 col-12">
                    <div className="form-holder">
                        {isLoadingContactSubmission ? <span className="calc_loader"></span> : ""}
                        <h2 className="second-heading">{t("contact_page.form.request_demo")}</h2>
                        <form onSubmit={SubmitHandler}>
                            <div className="field-row">
                                <label htmlFor="name" className="form-label">{t("contact_page.form.fields.name")}<span className="required-field">*</span></label>
                                <input type="text" className="form-control" name="username" id="name" placeholder={t("contact_page.form.fields.name")}
                                       value={formData.username}
                                       onChange={InputChangeHandler}
                                />
                                {formErrors.username && <span className="error-color">{formErrors.username}</span>}
                            </div>
                            <div className="field-row">
                                <label htmlFor="email" className="form-label">{t("contact_page.form.fields.email")}<span className="required-field">*</span></label>
                                <input type="text" className="form-control" name="email" id="email" placeholder={t("contact_page.form.fields.email")}
                                       value={formData.email}
                                       onChange={InputChangeHandler}
                                />
                                {formErrors.email && <span className="error-color">{formErrors.email}</span>}
                            </div>
                            <div className="field-row">
                                <label htmlFor="contact" className="form-label">{t("contact_page.form.fields.contact_number")}<span className="required-field">*</span></label>
                                <input type="text" className="form-control" name="contact" id="contact" placeholder={t("contact_page.form.fields.contact_number")}
                                       value={formData.contact}
                                       onChange={InputChangeHandler}
                                />
                                {formErrors.contact && <span className="error-color">{formErrors.contact}</span>}
                            </div>
                            <div className="field-row">
                                <label htmlFor="message" className="form-label">{t("contact_page.form.fields.message")}</label>
                                <textarea className="form-control" name="message" id="message" placeholder={t("contact_page.form.fields.placeholder.message")} rows="3"
                                          value={formData.message}
                                          onChange={InputChangeHandler} >
                                </textarea>
                            </div>
                            <button className="btn btn-primary w-100">{t("contact_page.form.fields.submit")}</button>
                        </form>
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="text-holder">
                        <h2 className="second-heading">{t("contact_page.address_section.address_head")}</h2>
                        <p>{t("contact_page.address_section.address_text")}</p>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.426297444744!2d55.14865427445369!3d25.08742693613983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b5a4b6b0215%3A0x64471e629f09781c!2sLandmark%20Group!5e0!3m2!1sen!2s!4v1690955349535!5m2!1sen!2s"
                            width="100%"
                            height="300"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>
        </section>
            {thankyouState &&
                <div className="modal_msgSuccess d-flex align-items-center justify-content-center">
                    <div className="msgBlock">
                        <span className="img-done">
                            <img src="/images/done-img.png" className="img-fluid w-100" alt="done image"/>
                        </span>
                        <span className="crossIcon" onClick={onCloseThankyouState}></span>
                        <h2 className="second-heading">{t("contact_page.success.thank_you")}</h2>
                        <p>{t("contact_page.success.message")}</p>
                    </div>
                </div>
            }
        </>

    )
}