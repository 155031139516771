import React from "react";

export const HeroSection = (props) => {
    return (
        <div className="heroSection inner">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-holder text-center">
                            <h1>{props.page}</h1>
                            <p>{props.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}