import React, {useEffect, useState} from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CountryModal} from "./CountryModal";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../../../context/TabContext";
import {ApiWrapper} from "../../../api/ApiWrapper";


const monthWithPercentage = [
    {
        month: 12,
        percentage: "100%",
        value:1
    },
    {
        month: 11,
        percentage: "94%",
        value:0.94
    },
    {
        month: 10,
        percentage: "88%",
        value:0.88
    },
    {
        month: 9,
        percentage: "82%",
        value:0.82
    },
    {
        month: 8,
        percentage: "75%",
        value:0.75
    },
    {
        month: 7,
        percentage: "67%",
        value:0.67
    },
    {
        month: 6,
        percentage: "59%",
        value:0.59
    },
    {
        month: 5,
        percentage: "50%",
        value:0.50
    },
    {
        month: 4,
        percentage: "41%",
        value:0.41
    },
    {
        month: 3,
        percentage: "32%",
        value:0.32
    },
    {
        month: 2,
        percentage: "22%",
        value:0.22
    },
    {
        month: 1,
        percentage: "11%",
        value:0.11
    },


];
export const Market = (
    
    {
        onMarketFinish,
        onRewardsUnitSelection,
        setVoucher,
        voucherList,
        vouchers,
        merchants,
        setMerchants,
        merchantNumberList,
        validityFrom,
        setValidityFrom,
        validityTo,
        selectedValidityTo,
        maxValidityTo,
        setValidityTo,
        setSelectedValidityTo,
        setMaxValidityTo,
        selectedCountries,
        onCountrySelection,
        setActiveTab,
        country,
        contentLicenseFee,
        setContentLicenseFee,
        selectedContentLicenseFee,
        setSelectedContentLicenseFee,
        setIsLoadingContentLicenseFee,
        maxValidityFrom,
        setMaxValidityFrom,
    }
    ) => {

    useEffect(()=> {
        (async () => {
            const data = await ApiWrapper({
                path:"/locationpercentage",
                method:"GET",
                data:""
            });
            localStorage.setItem("locationPercentage", JSON.stringify(data.data));
        })();
    },[]);
        
    const {t} = useTranslation();

    const rewardsUnitList = [
            {
                id: 0,
                label: t("calculator.select"),
                value: "select",
            },
            {
                id: 1,
                label: 1000,
                value: 1000,
            },
            {
                id: 2,
                label: 2000,
                value: 2000,
            },
            {
                id: 3,
                label: 3000,
                value: 3000,
            },
            {
                id: 4,
                label: 4000,
                value: 4000,
            },
            {
                id: 5,
                label: 5000,
                value: 5000,
            },
            {
                id: 6,
                label: 6000,
                value: 6000,
            },
            {
                id: 7,
                label: 7000,
                value: 7000,
            },
            {
                id: 8,
                label: 8000,
                value: 8000,
            },
            {
                id: 9,
                label: 9000,
                value: 9000,
            },
            {
                id: 10,
                label: 10000,
                value: 10000,
            },
        ];

    const [formErrors, setFormErrors] = useState({});
    const [rewardsUnit, setRewardsUnit] = useState("");
    const [countryDropdown, setCountryDropdown] = useState(false);
    // const [packagesData, setPackagesData] = useState([]);
    const getPackagesData = localStorage.getItem("packagesData");
    const [monthPercentageValue, setMonthPercentageValue] = useState("");

    const [rewardsUnitSelected, setRewardsUnitSelected] = useState(false);
    const tab = useTabContext();

    let validityToYear = validityTo.getFullYear();
    let validityFromYear = validityFrom.getFullYear();

    let yearDiff = validityToYear - validityFromYear;
    let monthDiff = validityTo.getMonth() - validityFrom.getMonth();
    let totalMonths = yearDiff * 12 + monthDiff;

    const RewardsUnitHandler = async (event) => {
        setRewardsUnit(event.target.value);
        setIsLoadingContentLicenseFee(true);
        if(event.target.value !== 'select') {
            const element = JSON.parse(getPackagesData).filter((item) =>
                item.no_of_keys === event.target.value
                && item.merchant_location === country
                && item.no_of_merchants == merchants
            );
            const rewardsUnitAmount = parseFloat(element[0].price.replace(/,/g, ''));
            contentLicenseFee = rewardsUnitAmount * vouchers;
            const monthPercentage = monthWithPercentage.filter((item) =>
                item.month === totalMonths
            );
            if(totalMonths > 0) {
                setMonthPercentageValue(monthPercentage[0].value);
                contentLicenseFee = contentLicenseFee * monthPercentage[0].value;
            }

            setContentLicenseFee(contentLicenseFee);
            setSelectedContentLicenseFee(contentLicenseFee);
        } else {
            setContentLicenseFee(0);
        }
        setIsLoadingContentLicenseFee(false);
        setRewardsUnitSelected(true);
        onRewardsUnitSelection(event.target.value);
    }

    const VoucherPerMerchantHandler = (event) => {
        setVoucher(event.target.value);

        const element = JSON.parse(getPackagesData).filter((item) =>
            item.no_of_keys === rewardsUnit
            && item.merchant_location === country
            && item.no_of_merchants == merchants
        );
        validityToYear = selectedValidityTo.getFullYear();
        validityFromYear = validityFrom.getFullYear();

        yearDiff = validityToYear - validityFromYear;
        monthDiff = selectedValidityTo.getMonth() - validityFrom.getMonth();
        totalMonths = yearDiff * 12 + monthDiff;
        const monthPercentage = monthWithPercentage.filter((item) =>
            item.month === totalMonths
        );
        const rewardsUnitAmount = parseFloat(element[0].price.replace(/,/g, ''));
        //handling voucher
        contentLicenseFee = rewardsUnitAmount * event.target.value;
        //handling months percentage
        // let licenseFee = contentLicenseFee * monthPercentage[0].value;
        let licenseFee = contentLicenseFee;
        if (totalMonths > 0) {
            licenseFee = licenseFee * monthPercentage[0].value;
            setMonthPercentageValue(monthPercentage[0].value);
        }
        //handling regions percentage
        if (selectedCountries.length > 1) {
            const locationPercentageData = localStorage.getItem("locationPercentage");
            selectedCountries.filter((region, index) => {
                const element = JSON.parse(locationPercentageData).filter((item) =>
                    item.location_number === index + 1
                );
                licenseFee = licenseFee * element[0].percentage_value
            })
        }
        setContentLicenseFee(licenseFee);
    }

    const MerchantNumberHandler = (event) => {
        setMerchants(event.target.value);

        const element = JSON.parse(getPackagesData).filter((item) =>
            item.no_of_keys === rewardsUnit
            && item.merchant_location === country
            && item.no_of_merchants == event.target.value
        );
        const rewardsUnitAmount = parseFloat(element[0].price.replace(/,/g, ''));
        //voucher percentage handling
        let licenseFee = rewardsUnitAmount * vouchers;
        //regions percentage handling
        if(selectedCountries.length > 1) {
            const locationPercentageData = localStorage.getItem("locationPercentage");
            selectedCountries.filter((region, index) =>{
                const element = JSON.parse(locationPercentageData).filter((item) =>
                    item.location_number === index+1
                );
                licenseFee = licenseFee * element[0].percentage_value
            })
        }
        if(totalMonths > 0) {
            const monthPercentage = monthWithPercentage.filter((item) =>
                item.month === totalMonths
            );
            //month percentage handling
            licenseFee = licenseFee * monthPercentage[0].value;
            setMonthPercentageValue(monthPercentage[0].value);
        }
        setContentLicenseFee(licenseFee);
        setSelectedContentLicenseFee(licenseFee);
    }

    const validityFromHandler = (date) => {
        setValidityFrom(date);
        const newValidityTo = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
        setValidityTo(newValidityTo);
        setMaxValidityTo(newValidityTo);
        validityToYear = selectedValidityTo.getFullYear();
        validityFromYear = date.getFullYear();

        yearDiff = validityToYear - validityFromYear;
        monthDiff = selectedValidityTo.getMonth() - date.getMonth();
        totalMonths = yearDiff * 12 + monthDiff;
        if(totalMonths > 12) {
            console.log(date, new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()));
            setSelectedValidityTo(new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()));
        }
        const monthPercentage = monthWithPercentage.filter((item) =>
            item.month === totalMonths
        );
        let licenseFee = selectedContentLicenseFee;
        if(totalMonths > 0 && totalMonths <= 12) {
            setMonthPercentageValue(monthPercentage[0].value);
            licenseFee = selectedContentLicenseFee * monthPercentage[0].value;
        }
        // setMonthPercentageValue(monthPercentage[0].value);


        // let licenseFee = selectedContentLicenseFee * monthPercentage[0].value;
        licenseFee = licenseFee * parseFloat(vouchers);
        if(selectedCountries.length > 1) {
            const locationPercentageData = localStorage.getItem("locationPercentage");
            selectedCountries.filter((region, index) =>{
                const element = JSON.parse(locationPercentageData).filter((item) =>
                    item.location_number === index+1
                );
                licenseFee = licenseFee * element[0].percentage_value
            })
        }
        // contentLicenseFee = selectedContentLicenseFee * monthPercentage[0].value;
        setContentLicenseFee(licenseFee);
    }

    const validityToHandler = (date) => {
        setValidityTo(date);
        setSelectedValidityTo(date);
        setMaxValidityFrom(date);

        validityToYear = date.getFullYear();
        validityFromYear = validityFrom.getFullYear();

        yearDiff = validityToYear - validityFromYear;
        monthDiff = date.getMonth() - validityFrom.getMonth();
        totalMonths = yearDiff * 12 + monthDiff;
        const monthPercentage = monthWithPercentage.filter((item) =>
            item.month === totalMonths
        );
        let licenseFee = selectedContentLicenseFee;
        if(totalMonths > 0 && totalMonths <= 12) {
            setMonthPercentageValue(monthPercentage[0].value);
            licenseFee = selectedContentLicenseFee * monthPercentage[0].value;
        }
        // let licenseFee = selectedContentLicenseFee * monthPercentage[0].value;
        licenseFee = licenseFee * parseFloat(vouchers);
        if(selectedCountries.length > 1) {
            const locationPercentageData = localStorage.getItem("locationPercentage");
            selectedCountries.filter((region, index) =>{
                const element = JSON.parse(locationPercentageData).filter((item) =>
                    item.location_number === index+1
                );
                licenseFee = licenseFee * element[0].percentage_value
            })
        }
        setContentLicenseFee(licenseFee);
    }

    const validateForm = () => {
        let errors = {};

        if (!rewardsUnit || rewardsUnit === 'select') {
            errors.rewardsUnit = t("calculator.market_fields.validation_messages.rewards_unit");
        }

        // Set formErrors state
        setFormErrors(errors);

        // Return true if there are no errors
        return Object.keys(errors).length === 0;
    };

    const MarketSubmitHandler = (event) => {
        event.preventDefault();
        if(validateForm()) {
            const marketData = {
                rewardsUnit: rewardsUnit,
                defaultCountry: country,
                noOfMerchants: merchants,
                noOfCountries: "",
                offersPerMerchant: vouchers,
                validityFrom: validityFrom,
                validityTo: validityTo
            }
            onMarketFinish(marketData);
            tab.setActiveTab(t("calculator.tabs.marketing"));
        }
    }

    const CountryModalHandler = () => {
        setCountryDropdown(true);
    }

    return (
        <div>
            <form onSubmit={MarketSubmitHandler}>
                <div className="tab-pane form-wrap">
                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="app_units">{t("calculator.market_fields.app_users")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select
                                    className="form-select"
                                    id="app_units"
                                    aria-label="Business Type"
                                    value={rewardsUnit}
                                    onChange={RewardsUnitHandler}
                                >
                                    {rewardsUnitList.map((rewardsUnit) => (
                                        <option key={rewardsUnit.id} value={rewardsUnit.value} defaultValue={rewardsUnit[0]}>{rewardsUnit.label}</option>
                                    ))}
                                </select>
                            </div>
                            {formErrors.rewardsUnit && <span className="error-color">{formErrors.rewardsUnit}</span>}
                        </div>
                    </div>
                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="home_country">{t("calculator.market_fields.country")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select className="form-select" id="home_country">
                                    <option defaultValue>{t("calculator.market_fields.region_list.ksa")}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="merchant_nums">{t("calculator.market_fields.merchant_number")}</label>
                        </div>

                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select disabled={!rewardsUnitSelected}
                                    className="form-select"
                                    id="app_units"
                                    aria-label="Business Type"
                                    value={merchants}
                                    onChange={MerchantNumberHandler}
                                >
                                    {merchantNumberList.map((merchantNumber) => (
                                        <option key={merchantNumber.id} value={merchantNumber.value}>{merchantNumber.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="regions_nums">{t("calculator.market_fields.region_number")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <input
                                    disabled={!rewardsUnitSelected}
                                    type="text" readOnly
                                   className="form-control"
                                   id="regions_nums"
                                   placeholder="Choose Countries"
                                   value={selectedCountries.length}
                                   onClick={CountryModalHandler}
                                />
                                {countryDropdown &&
                                    <CountryModal
                                        onCountrySelection={onCountrySelection}
                                        onClose={() => setCountryDropdown(false)}
                                        selectedCountries={selectedCountries}
                                        contentLicenseFee={contentLicenseFee}
                                        setContentLicenseFee={setContentLicenseFee}
                                        selectedContentLicenseFee={selectedContentLicenseFee}
                                        setSelectedContentLicenseFee={setSelectedContentLicenseFee}
                                        vouchers={vouchers}
                                        monthPercentageValue={monthPercentageValue}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center field-wrap">
                        <div className="col-md-8 col-12">
                            <label className="form-label mb-md-0" htmlFor="merchant_offers">{t("calculator.market_fields.merchant_offer")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <select
                                    disabled={!rewardsUnitSelected}
                                    className="form-select"
                                    id="merchant_offers"
                                    aria-label="Business Type"
                                    value={vouchers}
                                    onChange={VoucherPerMerchantHandler}
                                >
                                    {voucherList.map((voucher) => (
                                        <option key={voucher.id} value={voucher.value}>{voucher.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center field-wrap validity-row">
                        <div className="col-md-3 col-12">
                            <label className="form-label mb-md-0" htmlFor="field-validity">{t("calculator.market_fields.validity")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <DatePicker
                                    className="form-control"
                                    selected={validityFrom}
                                    onChange={validityFromHandler}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    maxDate={maxValidityFrom}
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </div>
                        </div>
                        <div className="col-md-1 col-12 text-center">
                            <label className="form-label mb-md-0 text-start" htmlFor="field-validity-to">{t("calculator.market_fields.to")}</label>
                        </div>
                        <div className="col-md-4 col-12 ps-md-0">
                            <div className="select-wrap">
                                <DatePicker
                                    className="form-control"
                                    selected={selectedValidityTo}
                                    onChange={validityToHandler}
                                    dateFormat="dd/MM/yyyy"
                                    minDate={validityFrom}
                                    maxDate={maxValidityTo}
                                    showYearDropdown
                                    scrollableYearDropdown
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary d-block w-100">{t("calculator.next")}</button>
            </form>
        </div>
    )
}