import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className="accordion accordion-flush d-md-flex flex-wrap" id="footerAccordion">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#footerCollapseOne" aria-expanded="true"
                                            aria-controls="footerCollapseOne">
                                        {t("footer.rewards_and_loyalty")}
                                    </button>
                                </h2>
                                <div id="footerCollapseOne" className="accordion-collapse collapse show"
                                     aria-labelledby="footerHeadingOne" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="https://business.theentertainerme.com/program-platforms" target="_blank">{t("footer.rewards_and_loyalty_links.platforms")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/customer-loyalty-programs" target="_blank">{t("footer.rewards_and_loyalty_links.customer_loyalty")}</Link></li>
                                            {/*<li><Link to="/">{t("footer.rewards_and_loyalty_links.employee_rewards")}</Link></li>*/}
                                            <li><Link to="https://business.theentertainerme.com/integrations" target="_blank">{t("footer.rewards_and_loyalty_links.solutions_integration")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingTwo">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#footerCollapseTwo"
                                            aria-expanded="false" aria-controls="footerCollapseTwo">
                                        {t("footer.company")}
                                    </button>
                                </h2>
                                <div id="footerCollapseTwo" className="accordion-collapse collapse"
                                     aria-labelledby="footerHeadingTwo" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="/about">{t("footer.company_links.about_us")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/industry" target="_blank">{t("footer.company_links.industries")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="footerHeadingThree">
                                    <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#footerCollapseThree"
                                            aria-expanded="false" aria-controls="footerCollapseThree">
                                        {t("footer.help_and_support")}
                                    </button>
                                </h2>
                                <div id="footerCollapseThree" className="accordion-collapse collapse"
                                     aria-labelledby="footerHeadingThree" data-bs-parent="#footerAccordion">
                                    <div className="accordion-body">
                                        <ul className="list-unstyled footer_links">
                                            <li><Link to="https://business.theentertainerme.com/resources" target="_blank">{t("footer.help_and_support_links.resource_center")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/press-room" target="_blank">{t("footer.help_and_support_links.press_room")}</Link></li>
                                            <li><Link to="https://business.theentertainerme.com/blog-v2" target="_blank">{t("footer.help_and_support_links.blog")}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 right-column text-end">
                        <Link to="#" className="ft-logo">
                            <img src="/images/TheENTERTAINER.svg" alt="TheENTERTAINER" className="img-fluid w-100" />
                        </Link>
                        <ul className="list-unstyled social-list">
                            <li>
                                <Link to="https://www.youtube.com/" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.2803 14.6702H17.6158C18.3582 14.6702 18.9613 14.0708 18.9658 13.3284V11.1438C18.9525 10.411 18.3569 9.82272 17.6239 9.81836H17.2885C16.546 9.81836 15.943 10.4178 15.9385 11.1602V13.3447C15.9518 14.0775 16.5474 14.6659 17.2803 14.6702ZM16.9449 11.062C16.9451 10.7819 17.1723 10.5549 17.4524 10.5551C17.7324 10.5553 17.9593 10.7821 17.9594 11.062V13.4102C17.9592 13.6903 17.7319 13.9174 17.4518 13.9172C17.1719 13.9169 16.9451 13.6901 16.9449 13.4102V11.062Z"
                                            fill="#484848"/>
                                        <path
                                            d="M13.2947 14.5879H14.4811V11.9779L15.8311 8.27979H14.7266L13.9083 10.7343L13.0902 8.27979H11.8711L13.3111 11.9779L13.2947 14.5879Z"
                                            fill="#484848"/>
                                        <path
                                            d="M20.6584 14.6702C21.1359 14.6243 21.5834 14.4164 21.9265 14.0811V14.5884H22.9084V9.81843H21.9265V13.5166C21.9265 13.5166 21.5911 13.9093 21.272 13.9093C20.9529 13.9093 20.9365 13.6557 20.9365 13.6557V9.81836H19.8975V14.0157C19.9542 14.3921 20.2777 14.6704 20.6584 14.6702Z"
                                            fill="#484848"/>
                                        <path
                                            d="M19.9878 20.1519C19.7906 20.1615 19.6065 20.2535 19.4805 20.4055V23.7928C19.6099 23.9359 19.7948 24.0164 19.9878 24.0137C20.4951 24.0137 20.4951 23.4492 20.4951 23.4492V20.7165C20.4951 20.7164 20.4051 20.1519 19.9878 20.1519Z"
                                            fill="#484848"/>
                                        <path
                                            d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
                                            fill="#484848"/>
                                        <path
                                            d="M23.8745 20.1519C23.3672 20.1519 23.3672 20.7164 23.3672 20.7164V21.4446H24.3817V20.7164C24.3818 20.7164 24.3818 20.1519 23.8745 20.1519Z"
                                            fill="#484848"/>
                                        <path
                                            d="M24.2525 15.7164C24.2383 15.7167 24.2241 15.717 24.2099 15.7175H24.1935C24.1935 15.7175 21.109 15.5457 17.9999 15.5457C14.8909 15.5457 11.7899 15.7093 11.7899 15.7093C10.4351 15.6641 9.30009 16.7258 9.25495 18.0806C9.25404 18.1083 9.25355 18.1361 9.25362 18.1638C9.09738 19.193 9.01259 20.2319 9 21.2729C9.01153 22.3248 9.09626 23.3746 9.25362 24.4147C9.25284 25.7704 10.3512 26.8699 11.7068 26.8706C11.7345 26.8706 11.7623 26.8702 11.79 26.8693C11.79 26.8693 14.8418 27.0329 18 27.0329C21.1582 27.0329 24.21 26.8693 24.21 26.8693C25.5649 26.9145 26.6998 25.8528 26.745 24.4979C26.7459 24.4702 26.7464 24.4424 26.7463 24.4147C26.9029 23.3745 26.9876 22.3248 26.9999 21.2729C26.9896 20.2209 26.9048 19.171 26.7463 18.131C26.7244 16.7757 25.608 15.6946 24.2525 15.7164ZM14.3263 18.4583H13.0909V24.8238H11.8718V18.4583H10.6363V17.411H14.3509L14.3263 18.4583ZM17.5418 24.8238H16.5191V24.3411C16.171 24.6653 15.7247 24.864 15.2509 24.9057C14.8703 24.9136 14.5437 24.6361 14.49 24.2593V19.2602H15.5455V23.932C15.5455 23.932 15.5455 24.1775 15.8809 24.1775C16.2164 24.1775 16.56 23.7766 16.56 23.7766V19.2602H17.5745L17.5418 24.8238ZM20.5772 24.9057C20.1469 24.9095 19.7401 24.7092 19.4809 24.3657V24.8238H18.3763V17.4111H19.4727V19.8166C19.7533 19.4987 20.1464 19.3022 20.5691 19.2684C21.2482 19.2684 21.5018 19.833 21.5018 20.5611L21.5099 23.613C21.5099 23.613 21.5099 24.9057 20.5772 24.9057ZM25.4781 22.2711H23.3672V23.482C23.3672 23.482 23.3672 24.0466 23.8745 24.0466C24.3818 24.0466 24.3818 23.482 24.3818 23.482V22.9093H25.4782V23.7929C25.3314 24.5012 24.6768 24.9869 23.9563 24.922C23.21 24.9892 22.522 24.5144 22.3199 23.7929V20.6347C22.3427 19.8488 22.9982 19.2301 23.7842 19.2529C23.8418 19.2546 23.8993 19.2597 23.9563 19.2683C24.6855 19.164 25.3611 19.6705 25.4655 20.3995C25.4767 20.4774 25.4809 20.5561 25.4782 20.6347V22.2711H25.4781Z"
                                            fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.facebook.com/" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.3629 13.9095V15.5459H14.7266V18.0004H16.3629V25.364H19.6356V18.0004H21.812L22.0902 15.5459H19.6356V14.114C19.6356 13.4513 19.7011 13.0995 20.7238 13.0995H22.0902V10.6367H19.8975C17.2793 10.6368 16.3629 11.864 16.3629 13.9095Z"
                                            fill="#484848"/>
                                        <path
                                            d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
                                            fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://www.instagram.com/" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
                                            fill="#484848"/>
                                        <path
                                            d="M22.9092 9.81812H13.0911C11.2836 9.81812 9.81836 11.2834 9.81836 13.0908V22.909C9.81836 24.7164 11.2836 26.1817 13.0911 26.1817H22.9092C24.7167 26.1817 26.1819 24.7164 26.1819 22.909V13.0909C26.182 11.2834 24.7167 9.81812 22.9092 9.81812ZM21.2728 12.2727H23.7274V14.7272H21.2728V12.2727ZM18.0001 14.7273C19.8076 14.7273 21.2728 16.1925 21.2728 18C21.2728 19.8074 19.8076 21.2727 18.0001 21.2727C16.1927 21.2727 14.7274 19.8074 14.7274 18C14.7274 16.1925 16.1927 14.7273 18.0001 14.7273ZM24.5456 22.909C24.5456 23.8128 23.8129 24.5454 22.9092 24.5454H13.0911C12.1873 24.5454 11.4547 23.8128 11.4547 22.909V17.1817H13.1728C12.6815 19.8481 14.4446 22.4079 17.1109 22.8993C19.7772 23.3907 22.337 21.6275 22.8284 18.9612C22.8869 18.6442 22.9139 18.3223 22.9092 18C22.9051 17.7254 22.8778 17.4517 22.8274 17.1818H24.5455V22.909H24.5456Z"
                                            fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                            <li>
                                <Link to="https://twitter.com/" className="social-icon" target="_blank">
                                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M26.5503 11.6999C25.8898 12.0913 25.1677 12.3679 24.4148 12.5181C23.775 11.847 22.8875 11.4683 21.9603 11.4708C20.1338 11.4571 18.6371 12.9172 18.6057 14.7435C18.6065 14.997 18.6367 15.2497 18.6957 15.4963C16.0057 15.3693 13.4934 14.1161 11.7739 12.0436C11.4798 12.5392 11.3217 13.1037 11.3157 13.6799C11.3158 14.7911 11.8796 15.8262 12.813 16.429C12.28 16.4117 11.7586 16.2687 11.2912 16.0117C11.2912 16.0117 11.2912 16.0117 11.2912 16.0526C11.2858 17.6485 12.416 19.0227 13.983 19.3253C13.4891 19.457 12.9719 19.4766 12.4694 19.3826C12.9212 20.7345 14.1779 21.6532 15.603 21.6736C14.4166 22.5962 12.9578 23.0997 11.4549 23.1054C11.1814 23.1051 10.9082 23.0887 10.6367 23.0563C12.1764 24.0303 13.9612 24.5467 15.7831 24.5454C20.9787 24.6403 25.2676 20.5053 25.3625 15.3097C25.3635 15.2547 25.364 15.1996 25.364 15.1445C25.364 15.0027 25.364 14.8608 25.364 14.719C26.0053 14.2491 26.5591 13.6703 27.0004 13.009C26.3862 13.278 25.7355 13.4544 25.0695 13.5326C25.7699 13.1226 26.2965 12.4709 26.5503 11.6999Z"
                                            fill="#484848"/>
                                        <path
                                            d="M18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0ZM18 34.3636C8.96259 34.3636 1.63638 27.0374 1.63638 18C1.63638 8.96259 8.96259 1.63638 18 1.63638C27.0374 1.63638 34.3636 8.96259 34.3636 18C34.3636 27.0374 27.0374 34.3636 18 34.3636Z"
                                            fill="#484848"/>
                                    </svg>
                                </Link>
                            </li>
                        </ul>
                        <p>© {t("footer.entertainer_copy_write")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
