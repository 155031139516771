import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {json} from "react-router-dom";


export const CountryModal = (
    {
        onClose,
        onCountrySelection,
        selectedCountries,
        contentLicenseFee,
        setContentLicenseFee,
        selectedContentLicenseFee,
        setSelectedContentLicenseFee,
        vouchers,
        locationPercentage,
        monthPercentageValue,
    }
    ) => {

    // const [locationPercentage, setLocationPercentage] = useState([]);
    // const locationPercentageEndpoint = process.env.REACT_APP_LOCATION_PERCENTAGE_API_ENDPOINT;
    let locationPercentageData = localStorage.getItem("locationPercentage");
    // useEffect(() => {
    //     fetch(locationPercentageEndpoint)
    //         .then((response) => response.json())
    //         .then((data) => {
    //             localStorage.setItem("locationPercentage", JSON.stringify(data.data));
    //             locationPercentageData = localStorage.getItem("locationPercentage");
    //             setLocationPercentage(data.data);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // },[setLocationPercentage]);
    const {t} = useTranslation();

    const handleCheckboxChange = (event) => {

        const { value, checked } = event.target;

        if(checked) {
            onCountrySelection((prevSelected) => [...prevSelected, value]);
            const element = JSON.parse(locationPercentageData).filter((item) =>
                item.location_number === selectedCountries.length+1
            );
            // console.log(element[0].percentage_value, "element[0].percentage_value");
            // console.log(selectedContentLicenseFee, 'selectedContentLicenseFee');
            let licenseFee = selectedContentLicenseFee * element[0].percentage_value;
            licenseFee = licenseFee * parseFloat(vouchers);
            licenseFee = licenseFee * monthPercentageValue;
            // const voucher = vouchers;
            // console.log("voucher ", voucher, monthPercentageValue);
            // console.log(licenseFee,'licenseFee')
            setContentLicenseFee(licenseFee);
            // setSelectedContentLicenseFee(licenseFee);
        } else {
            onCountrySelection((prevSelected) => prevSelected.filter((item) => item !== value));
            const element = JSON.parse(locationPercentageData).filter((item) =>
                item.location_number === selectedCountries.length-1
            );
            let licenseFee = selectedContentLicenseFee * element[0].percentage_value;
            licenseFee = licenseFee * parseFloat(vouchers);
            licenseFee = licenseFee * monthPercentageValue;
            setContentLicenseFee(licenseFee);
            // setSelectedContentLicenseFee(licenseFee);
        }
    }

    return (
        <div className="viewModal">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="reg_wrap">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="KSA"
                                            id="reg_ksa"
                                            onChange={()=>{}}
                                            checked={selectedCountries?.includes("KSA") || false}
                                        />
                                        <label className="form-check-label" htmlFor="reg_ksa">
                                            {t("calculator.market_fields.region_list.ksa")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="UAE"
                                            id="reg_uae"
                                            checked={selectedCountries?.includes("UAE") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_uae">
                                        {t("calculator.market_fields.region_list.uae")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Qatar"
                                            id="reg_qatar"
                                            checked={selectedCountries?.includes("Qatar") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_qatar">
                                        {t("calculator.market_fields.region_list.qatar")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Bahrain"
                                            id="reg_bahrain"
                                            checked={selectedCountries?.includes("Bahrain") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_bahrain">
                                        {t("calculator.market_fields.region_list.bahrain")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Kuwait"
                                            id="reg_kuwait"
                                            checked={selectedCountries?.includes("Kuwait") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_kuwait">
                                        {t("calculator.market_fields.region_list.kuwait")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Oman"
                                            id="reg_oman"
                                            checked={selectedCountries?.includes("Oman") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_oman">
                                        {t("calculator.market_fields.region_list.oman")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="South Africa"
                                            id="reg_sa"
                                            checked={selectedCountries?.includes("South Africa") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_sa">
                                        {t("calculator.market_fields.region_list.south_africa")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Singapore"
                                            id="reg_singapore"
                                            checked={selectedCountries?.includes("Singapore") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_singapore">
                                        {t("calculator.market_fields.region_list.singapore")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Hong Kong"
                                            id="reg_hk"
                                            checked={selectedCountries?.includes("Hong Kong") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_hk">
                                        {t("calculator.market_fields.region_list.hong_kong")}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value="Malaysia"
                                            id="reg_malaysia"
                                            checked={selectedCountries?.includes("Malaysia") || false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="reg_malaysia">
                                        {t("calculator.market_fields.region_list.malaysia")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        className="btn btn-outline add w-100"
                                        onClick={onClose}
                                    >
                                        {t("calculator.market_fields.region_list.ok")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}