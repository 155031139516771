import React from "react";
import {useTranslation} from "react-i18next";

export const PartnersHelp = () => {
    const {t} = useTranslation();
    return (
        <section className="prt-services">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="second-heading">{t("partner_help.heading")}</h2>
                        <p></p>
                    </div>
                </div>
                <div className="row prt-cols">
                    <div className="col-md-4 col-12 column">
                        <div className="prt-block">
                            <div className="icon">
                                <img src="/images/10x-more.svg" alt="iphone" className="img-fluid"/>
                            </div>
                            <span className="title">{t("partner_help.tabOne")}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 column">
                        <div className="prt-block">
                            <div className="icon">
                                <img src="/images/better-conversion.svg" alt="iphone" className="img-fluid"/>
                            </div>
                            <span className="title">{t("partner_help.tabTwo")}</span>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 column">
                        <div className="prt-block">
                            <div className="icon">
                                <img src="/images/user-experience.svg" alt="iphone" className="img-fluid"/>
                            </div>
                            <span className="title">{t("partner_help.tabThree")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
