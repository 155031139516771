import React from "react";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../../context/TabContext";

export const AboutContent = () => {
    const {t} = useTranslation();
    return (
        <section className="about-content container">
            <div className="row align-items-center mb-4 flex-md-row-reverse">
                <div className="col-md-5 col-12 d-none d-md-block">
                    <div className="img-holder">
                        <img src="/images/about-img.png" alt="About Image" className="img-fluid w-100" />
                    </div>
                </div>
                <div className="col-md-7 col-12">
                    <div className="text-holder">
                        <h2 className="second-heading">{t("about_page.sec_heading")}</h2>
                        <div className="img-holder d-block d-md-none mb-4">
                            <img src="/images/about-img.png" alt="About Image" className="img-fluid w-100" />
                        </div>
                        <p>{t("about_page.sec_content")}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}