import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export const SummarySection = (props) => {

    const {t} = useTranslation()

    let validityToYear = props.validityTo.getFullYear();
    let validityFromYear = props.validityFrom.getFullYear();

    const yearDiff = validityToYear - validityFromYear;
    const monthDiff = props.validityTo.getMonth() - props.validityFrom.getMonth();

    const totalMonths = yearDiff * 12 + monthDiff;

    let setupFee = props.initialSetupFee;
    let contentLicenseFee = props.contentLicenseFee;
    let marketingFee = props.marketingFee;
    let advanceReportingFee = props.advanceReportingFee;
    let other = marketingFee + advanceReportingFee;
    let totalAnnualFee = (props.initialSetupFee + props.contentLicenseFee + other).toFixed(2);

    const MonthlyClickHandler = () => {
        props.setIsMonthly(!props.isMonthly);
    }

    if(props.isMonthly) {
        setupFee = setupFee / totalMonths;
        contentLicenseFee = contentLicenseFee / totalMonths;
        marketingFee = marketingFee / totalMonths;
        advanceReportingFee = advanceReportingFee / totalMonths;
        other = other / totalMonths;
        totalAnnualFee = totalAnnualFee / totalMonths;
    }

    return (
        <div className="col-xl-5 col-12">
            <div className="calc-block">
                <div className="head d-flex justify-content-between align-items-center">
                    <h2 className="second-heading">{t("calculator.summary_fields.heading")}</h2>
                    {/*{props.activeTab === 'Marketing' &&*/}
                        <div className="form-check form-switch">
                            <input
                                // disabled={props.activeTab !== 'Marketing'}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="monthlyChecked"
                                onClick={MonthlyClickHandler}
                            />
                            <label className="form-check-label"
                                   htmlFor="monthlyChecked"><span>{t("calculator.summary_fields.monthly")}</span></label>
                        </div>
                    {/*}*/}
                </div>
                <ul className="sum-list">
                    <li className="d-flex justify-content-between align-items-center">
                        <span className="title">{t("calculator.summary_fields.setup_fee")}</span>
                        <span className="sum">{props.isLoadingSetupFee ? <span className="calc_loader"></span> : `${t("calculator.currency")}`+setupFee.toLocaleString('en-US')}</span>
                    </li>
                    <li className="d-flex justify-content-between align-items-center">
                        <span className="title">{t("calculator.summary_fields.license_fee")}</span>
                        <span className="sum">{props.isLoadingContentLicenseFee ? <span className="calc_loader"></span> : `${t("calculator.currency")}`+contentLicenseFee.toLocaleString('en-US')}</span>
                    </li>
                    <li>
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="title">{t("calculator.summary_fields.other")}</span>
                            <span className="sum">{t("calculator.currency")}{other.toLocaleString('en-US')}</span>
                        </div>
                        <ul className="sub-list">
                            <li className="d-flex justify-content-between align-items-center">
                                <span className="title">{t("calculator.summary_fields.marketing_fee")}</span>
                                <span className="sum">{t("calculator.currency")}{marketingFee.toLocaleString('en-US')}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center">
                                <span className="title">{t("calculator.summary_fields.advanced_reporting_label")}</span>
                                <span className="sum">{t("calculator.currency")}{advanceReportingFee.toLocaleString('en-US')}</span>
                            </li>
                        </ul>
                    </li>
                    <li className="d-flex justify-content-between align-items-center total">
                        <p>
                            <span className="title">{props.isMonthly ? t("calculator.summary_fields.monthly_fee") : t("calculator.summary_fields.total_fee")}</span>
                            <span className="note">{t("calculator.summary_fields.tax")}</span>
                            <span className="note">{t("calculator.summary_fields.estimated")}</span>
                        </p>
                        <span className="sum">
                            {t("calculator.currency")}
                            {localStorage.getItem("selectedLanguage") === 'ar' ? (setupFee > 0 ? "*" : "") : ""}
                            {totalAnnualFee.toLocaleString('en-US')}
                            {localStorage.getItem("selectedLanguage") === 'en' ? (setupFee > 0 ? "*" : "") : ""}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}