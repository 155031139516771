import React, {useState} from "react";
import {PricingTabs} from "../Calculator/PricingTabs";
import {SummarySection} from "../Calculator/SummarySection";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {Business} from "../Calculator/TabsContent/Business";
import {Market} from "../Calculator/TabsContent/Market";
import {Marketing} from "../Calculator/TabsContent/Marketing";
import {ThankyouSection} from "../Calculator/TabsContent/ThankyouSection";
import {useTranslation} from "react-i18next";
import {useTabContext} from "../../context/TabContext";


const reCAPTCHASiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY;

export const PricingSection = () => {
    
    const {t} = useTranslation();


    const vouchersList = [
        {
            id: 1,
            label: 1,
            value: 0.4
        },
        {
            id: 2,
            label: 2,
            value: 0.6,
        },
        {
            id: 3,
            label: 3,
            value: 1,
        },
    
    ];
    const numberOfMerchantsList = [
        {
            id: 1,
            label: 50,
            value: 50
        },
        {
            id: 2,
            label: 100,
            value: 100
        },
        {
            id: 3,
            label: 150,
            value: 150
        },
        {
            id: 4,
            label: 200,
            value: 200
        },
        {
            id: 5,
            label: 250,
            value: 250
        },
        {
            id: 6,
            label: 300,
            value: 300
        },
        {
            id: 7,
            label: 350,
            value: 350
        },
        {
            id: 8,
            label: 400,
            value: 400
        },
        {
            id: 9,
            label: 450,
            value: 450
        },
        {
            id: 10,
            label: 500,
            value: 500
        },
    ];
    const marketingCampaignList = [
        {
            id: 1,
            label: t("calculator.marketing_fields.Campaigns_dropdown.standard"),
            value: "standard",
        },
        {
            id: 2,
            label: t("calculator.marketing_fields.Campaigns_dropdown.quarterly"),
            value: "quarterly"
        },
        {
            id: 3,
            label: t("calculator.marketing_fields.Campaigns_dropdown.annual"),
            value: "annual"
        },
    ];
    const advanceReportingOptions = [
        {
            id: 1,
            label: t("calculator.marketing_fields.reporting_dropdown.yes"),
            value: "yes"
        },
        {
            id: 2,
            label: t("calculator.marketing_fields.reporting_dropdown.no"),
            value: "no"
        }
    ];
    const defaultDateToday = new Date();
    const defaultDateNextYear = new Date(defaultDateToday.getFullYear() + 1, defaultDateToday.getMonth(), defaultDateToday.getDate());
    const pricingTabList = [
        {
            id:1,
            label:t("calculator.tabs.business"),
            value:"Business",
            class:"nav-link",
        },
        {
            id:2,
            label:t("calculator.tabs.market"),
            value:"Markete",
            class: "nav-link"
        },
        {
            id:3,
            label:t("calculator.tabs.marketing"),
            value:"Marketing",
            class: "nav-link"
        },
    ];



    const [businessType, setBusinessType] = useState("");
    const [rewardsUnit, setRewardsUnit] = useState("");
    const [vouchers, setVouchers] = useState(vouchersList[2].value);
    const [country, setCountry] = useState("KSA");
    const [setupFee, setSetupFee] = useState(0);
    const [numberOfMerchants, setNumberOfMerchants] = useState(numberOfMerchantsList[0].value);
    const [marketingCampaign, setMarketingCampaign] = useState(marketingCampaignList[0].value);
    const [advanceReporting, setAdvanceReporting] = useState("no");
    // const [activeTab, setActiveTab] = useState("Business");
    const [validityFrom, setValidityFrom] = useState(defaultDateToday);
    const [maxValidityFrom, setMaxValidityFrom] = useState(defaultDateNextYear);
    const [validityTo, setValidityTo] = useState(defaultDateNextYear);
    const [selectedValidityTo, setSelectedValidityTo] = useState(defaultDateNextYear);
    const [maxValidityTo, setMaxValidityTo] = useState(defaultDateNextYear);
    const [selectedCountries, setSelectedCountries] = useState(["KSA"]);
    const [thankyouState, setThankyouState] = useState(false);
    const [businessFinishData, setBusinessFinishData] = useState("");
    const [marketFinishData, setMarketFinishData] = useState("");
    const [isLoadingSetupFee, setIsLoadingSetupFee] = useState(false);
    const [isLoadingContentLicenseFee, setIsLoadingContentLicenseFee] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [initialSetupFee, setInitialSetupFee] = useState(0);
    const [contentLicenseFee, setContentLicenseFee] = useState(0);
    const [selectedContentLicenseFee, setSelectedContentLicenseFee] = useState(0);
    const [marketingFee, setMarketingFee] = useState(0);
    const [advanceReportingFee, setAdvanceReportingFee] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [isMonthly, setIsMonthly] = useState(false);
    const tabContext = useTabContext();
    const onBusinessFinish = (businessData) => {
        setBusinessFinishData(businessData);
    }
    const onMarketFinish = (marketData) => {
        setMarketFinishData(marketData);
    }

    const onBusinessSelection = (businessOption) => {
        setBusinessType(businessOption);
    }

    const onRewardsUnitSelection = (rewardsUnit) => {
        setRewardsUnit(rewardsUnit);
    }
    // const onChangeTab = (tab) => {
    //     setActiveTab(tab);
    // }
    const onCountrySelection = (country) => {
        setSelectedCountries(country);
    }
    return (
        <section className="pricing-section" id="pricing_Section">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2 className="second-heading">{t("calculator.heading")}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7 col-12">
                        {thankyouState ? (
                            <ThankyouSection/>
                        ) : (
                            <>
                                <PricingTabs
                                    // activeTab={activeTab}
                                    // onChangeTab={onChangeTab}
                                    pricingTabList={pricingTabList}
                                />
                                <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHASiteKey}>
                                    <div className="tab-content" id="pills-tabContent">
                                        {tabContext.activeTab === t("calculator.tabs.business") && (
                                            <Business
                                                onBusinessFinish={onBusinessFinish}
                                                onBusinessSelection={onBusinessSelection}
                                                // activeTab={activeTab}
                                                // setActiveTab={setActiveTab}
                                                setSetupFee={setSetupFee}
                                                setIsLoadingSetupFee={setIsLoadingSetupFee}
                                                setInitialSetupFee={setInitialSetupFee}
                                            />
                                        )}
                                        {tabContext.activeTab === t("calculator.tabs.market") && (
                                            <Market
                                                onMarketFinish={onMarketFinish}
                                                onRewardsUnitSelection={onRewardsUnitSelection}
                                                vouchers={vouchers}
                                                setVoucher={setVouchers}
                                                voucherList={vouchersList}
                                                merchants={numberOfMerchants}
                                                setMerchants={setNumberOfMerchants}
                                                merchantNumberList={numberOfMerchantsList}
                                                validityFrom={validityFrom}
                                                maxValidityFrom={maxValidityFrom}
                                                setMaxValidityFrom={setMaxValidityFrom}
                                                setValidityFrom={setValidityFrom}
                                                validityTo={validityTo}
                                                selectedValidityTo={selectedValidityTo}
                                                maxValidityTo={maxValidityTo}
                                                setValidityTo={setValidityTo}
                                                setMaxValidityTo={setMaxValidityTo}
                                                setSelectedValidityTo={setSelectedValidityTo}
                                                selectedCountries={selectedCountries}
                                                onCountrySelection={onCountrySelection}
                                                // setActiveTab={setActiveTab}
                                                country={country}
                                                setContentLicenseFee={setContentLicenseFee}
                                                contentLicenseFee={contentLicenseFee}
                                                selectedContentLicenseFee={selectedContentLicenseFee}
                                                setSelectedContentLicenseFee={setSelectedContentLicenseFee}
                                                setIsLoadingContentLicenseFee={setIsLoadingContentLicenseFee}
                                            />
                                        )}
                                        {tabContext.activeTab === t("calculator.tabs.marketing") && (
                                            <Marketing
                                                businessFinishData={businessFinishData}
                                                marketFinishData={marketFinishData}
                                                marketingCampaign={marketingCampaign}
                                                setMarketingCampaign={setMarketingCampaign}
                                                marketingCampaignList={marketingCampaignList}
                                                advanceReporting={advanceReporting}
                                                setAdvanceReporting={setAdvanceReporting}
                                                advanceReportingOptions={advanceReportingOptions}
                                                setThankyouState={setThankyouState}
                                                initialSetupFee={initialSetupFee}
                                                contentLicenseFee={contentLicenseFee}
                                                marketingFee={marketingFee}
                                                setMarketingFee={setMarketingFee}
                                                setupFee={setupFee}
                                                advanceReportingFee={advanceReportingFee}
                                                setAdvanceReportingFee={setAdvanceReportingFee}
                                                selectedCountries={selectedCountries}
                                                isMonthly={isMonthly}
                                                summaryData={summaryData}
                                            />
                                        )}
                                    </div>
                                </GoogleReCaptchaProvider>
                            </>
                        )}
                    </div>
                    <SummarySection
                        businessType={businessType}
                        rewardsUnit={rewardsUnit}
                        voucherPerMerchant={vouchers}
                        country={country}
                        startupFee={setupFee}
                        merchants={numberOfMerchants}
                        marketingCampaign={marketingCampaign}
                        advanceReporting={advanceReporting}
                        // activeTab={activeTab}
                        validityFrom={validityFrom}
                        validityTo={validityTo}
                        selectedCountries={selectedCountries}
                        isLoadingSetupFee={isLoadingSetupFee}
                        setSummaryData={setSummaryData}
                        initialSetupFee={initialSetupFee}
                        isLoadingContentLicenseFee={isLoadingContentLicenseFee}
                        contentLicenseFee={contentLicenseFee}
                        marketingFee={marketingFee}
                        advanceReportingFee={advanceReportingFee}
                        totalFee={totalFee}
                        setTotalFee={setTotalFee}
                        setSetupFee={setSetupFee}
                        setContentLicenseFee={setContentLicenseFee}
                        setMarketingFee={setMarketingFee}
                        setAdvanceReportingFee={setAdvanceReportingFee}
                        isMonthly={isMonthly}
                        setIsMonthly={setIsMonthly}
                    />
                </div>
            </div>
        </section>
    );
}
