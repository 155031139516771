import React from "react";
import {HeroSection} from "../components/Common/HeroSection";
import {AboutContent} from "../components/About/AboutContent";
import {VisionContent} from "../components/About/VisionContent";
import {FeatureContent} from "../components/About/FeatureContent";
import {useTranslation} from "react-i18next";
import {StorySection} from "../components/Home/StorySection";
import {useTabContext} from "../context/TabContext";

export const About = () => {
    const {t} = useTranslation();
    const pageName = t("about_page.heading");
    const pageDescription = t("about_page.hero_content");
    return (
        <>
            <HeroSection page={pageName} description={pageDescription}/>
            <main id="main">
                <AboutContent />
                <VisionContent />
                <FeatureContent />
                <StorySection />
            </main>
        </>
    );
}
