import React from "react";
const BASE_PATH = process.env.REACT_APP_API_BASE_URL;
const TOKEN_API_PATH = process.env.REACT_APP_GET_AUTH_TOEKN_ENDPOINT;

export const ApiWrapper = async ({basePath=null, path, method, data}) => {

    let token = localStorage.getItem("access_token");
    if(!token || !checkTokenValidity()) {
        token = await getToken();
    }
    let request = {
        method: method,
        headers: {
            "Content-Type":"application/json",
            "AuthorizationToken":token
        },
    }
    if(method === "POST") {
        request = {
            ...request,
            body: data
        }
    }
    const response = await fetch(`${basePath || BASE_PATH}${path}`, request);
    return response.json();

}

const checkTokenValidity = () => {
    const currentTime = new Date();
    const expiryTime = new Date(localStorage.getItem("expiry"));
    return currentTime.getTime() > expiryTime.getTime() ? false : true;
}

export const getToken = async () => {
    const request = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cookie": "XSRF-TOKEN=27b850d6-27e4-4a59-96c7-17e11d475dd3",
            "AuthorizationToken": ""
        },
        body: new URLSearchParams({
            grant_type: "client_credentials",
            client_id: "2nd6c84ul42mquosvbhuvqh8j6",
            client_secret: "1j1ffq3t8okgapm5sqci8l5c98b5uggjj29uvgh59sevjk0f5vcd",
            scope: "b2b-api/RewardsApp"
        }).toString()
    };
    const response = await fetch(TOKEN_API_PATH, request);
    const data = await response.json();
    const secToMin = data.expires_in/60;
    const expiryTime = setExpiryTime(new Date(), secToMin).toString();
    localStorage.setItem("access_token", data.access_token);
    localStorage.setItem("expiry", expiryTime);
    return data.access_token;

}

const setExpiryTime =  function (dateTime, expiryMinutes) {
    return new Date(dateTime.getTime() + expiryMinutes*60000);
}