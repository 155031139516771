import React from "react";
import {HeroSection} from "../components/Common/HeroSection";
import {ContactForm} from "../components/Contact/ContactForm";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {TeamSection} from "../components/Contact/TeamSection";
import {useTranslation} from "react-i18next";
import {StorySection} from "../components/Home/StorySection";

export const Contact = () => {
    const {t} = useTranslation();
    const pageName = t("contact_page.heading");
    const pageDescription = t("contact_page.hero_content");
    const reCAPTCHASiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_KEY;
    return (
        <>
            <HeroSection page={pageName} description={pageDescription}/>
            <main id="main">
                <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHASiteKey}>
                    <ContactForm/>
                </GoogleReCaptchaProvider>
                <StorySection />
                <TeamSection/>
            </main>
        </>
    );
}
