import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export const ThankyouSection = () => {
    // const onCalculateAgainHandler = () => {
    //     const pricingSection = document.getElementById("pricing_Section");
    //     if(pricingSection) {
    //         pricingSection.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start',
    //         });
    //     }
    // }
    const {t} = useTranslation();
    return (
        <div className="thk_message text-center">
            <span className="img-done">
                <img src="/images/done-img.png" className="img-fluid w-100" alt="done image"/>
            </span>
            <h2 className="second-heading">{t("calculator.thank_you.heading")}</h2>
            <p>{t("calculator.thank_you.message")}</p>
            <div className="btn-wrap">
                <Link className="btn btn-primary d-block w-100"
                to="/contact#loyality_manager">
                {t("calculator.thank_you.btn_speak")}
                </Link>
                <button className="btn btn-outline d-block w-100" onClick={() =>  window.location.href='/'}>
                {t("calculator.thank_you.btn_calculate")}
                </button>
            </div>
        </div>
    )
}