import React from "react";
import {useTabContext} from "../../context/TabContext";

export const PricingTabs = (
    {
        // onChangeTab,
        pricingTabList
        // activeTab}
    }) => {
    const tabContext = useTabContext();
    return (
        <ul className="nav nav-pills mb-3">
            {pricingTabList.map((tab) => (
                <li className="nav-item" key={tab.id}>
                    <button disabled
                        className={`${tabContext.activeTab === tab.label ? tab.class + ' active' : tab.class}`}
                    >{tab.label}
                    </button>
                </li>
            ))}
        </ul>
    )
}